/**
 * Theme global.js
 */

function init()
{
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);

(function ($) {
    $(document).ready(function () {
        //Calculate white background height for submenu
        let sub_menu_height = $('nav.desktop .menu .menu-item-has-children > .sub-menu > li > .sub-menu').outerHeight();
        let sub_menu_bg_height = sub_menu_height + 82;
        $('nav.desktop .menu .menu-item-has-children > .sub-menu').css('height', sub_menu_bg_height);
    });

})(jQuery);
